var locked = false;
export default function(key) {
     locked = false;
    const arr =JSON.parse(localStorage.getItem("authorities"));
    if (arr instanceof Array) {
        findKey(arr,key);

    }
    return locked;

}
function findKey(arr,key) {
    for (let i = 0; i < arr.length; i++) {
        if (arr[i].other.uniqueKey===key) {
            return  locked = true;
        }else if (arr[i].children&& arr[i].children instanceof Array) {
           findKey(arr[i].children,key);
        }
    }
    return locked;
}
